/* Order List, Vue Component */
<template>
    <div>
    <v-card id="order-list">
    <v-card-title>Find Order To Book-In Item</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">

      <div class="d-flex align-center pb-5 flex-wrap w-100">

        <!-- <v-text-field v-model="search.Reference"
          dense
          outlined
          hide-details
          label="Search Reference"
          class="mb-4 mr-4"
        ></v-text-field> -->
        <v-text-field v-model="search.MemberId_Reference"
          dense
          outlined
          hide-details
          label="Member Reference"
          class="mb-4 mr-4"
        ></v-text-field>

        <v-text-field v-model="search.MemberId_FullName"
          dense
          outlined
          hide-details
          label="Member Name"
          class="mb-4 mr-4"
        ></v-text-field>

        <v-spacer></v-spacer>

        <v-text-field v-model="search.all"
          dense
          outlined
          hide-details
          label="Search All"
          class="mb-4 mr-4 ml-auto"
        ></v-text-field>

      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Reference`]="{item}">
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'order-view', params: { id: item.Id } }">
      {{ item.Reference }}
    </router-link>

    </template>


		<template #[`item.MemberId_Reference`]="{item}">
      <strong>{{ item.MemberId_Reference }}</strong>
    </template>

    <template #[`item.ProductId_Price`]="{item}">
      {{ Display.Money(item.ProductId_Price) }}
    </template>

    <template #[`item.StatusId_Name`]="{item}">
      <order-status-chip :status="item.StatusId_Name"></order-status-chip>
    </template>

    <template #[`item.ItemDescription`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.ItemDescription }}</div>
        </template>
        <span>{{ item.ItemDescription }}</span>
      </v-tooltip>
    </template>

    <template #[`item.DeliveryAddressId_Summary`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.DeliveryAddressId_Summary }}</div>
        </template>
        <span>{{ item.DeliveryAddressId_Summary }}</span>
      </v-tooltip>
    </template>

    <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-left">
        <v-btn color="success" small
        :to="{ name: 'order-bookitem', params: { id: item.Id } }">Book Item</v-btn>
      </div>
    </template>

    </v-data-table>

  </v-card>

  <v-card class="mt-6">
    <v-card-text>
      <h3>NO ORDER FOUND</h3>
      <p>If you are sure the item does not belong to any of the orders, add it to a fresh order that
        will be held for review.</p>
    <v-btn color="error" small
        :to="{ name: 'member-findforbooking' }">Find Member</v-btn>
    </v-card-text>
    </v-card>

  </div>

</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'
import OrderStatusSelect from '@/views/orderstatus/OrderStatusSelect'
import ProductSelect from '@/views/product/ProductSelect'
import ManifestSelect from '@/views/manifest/ManifestSelect'
//import MemberSelect from '@/views/member/MemberSelect'
import MemberLookup from '@/views/member/MemberLookup'
// import AddressSelect from '@/views/address/AddressSelect'
import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'

export default {
  components: {
    ActionsDropdown,
    OrderStatusSelect,
		ProductSelect,
		ManifestSelect,
		//MemberSelect,
    MemberLookup,
    OrderStatusChip,
		// AddressSelect
  },
  setup() {

  // define cols
  const tableColumns = [
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
    { text: 'Member', value: 'MemberId_Reference' },
		{ text: 'Member Name', value: 'MemberId_FullName' },
		{ text: 'Description', value: 'ItemDescription' },
		{ text: 'Product', value: 'OrderType' },
		{ text: 'Ret. Ref.', value: 'RetailerReference' },
    { text: 'Status', value: 'StatusId_Name' },
		{ text: 'Delivery', value: 'DeliveryAddressId_Summary' },
    { text: 'Reference', value: 'Reference' }
  ];

  // states needed for filter
  const filterOrderStatuses = computed(() => store.getters["app/OrderStatusAllowBook"]);

  // threee params for fetching
  const search = ref({});
  const filter = ref({
    StatusIds: filterOrderStatuses
  });
  const options = ref({
    sortBy: ['MemberId_Reference'],
    sortDesc: [false],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Orders || []);
  const totalItems = computed(() => store.state.app.OrdersTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // method for data loading via vuex
  const fetchData = () => {
    if (filterOrderStatuses.value == null || filterOrderStatuses.value.length == 0) return;
    store
      .dispatch('app/fetchOrders', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


  const booleanIcons = status => {
    if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
  }


    return {
      tableColumns,
      search,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      footerProps,
      booleanIcons,
      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
